import { Button } from '@material-ui/core'
import Snackbar from '@material-ui/core/Snackbar'
import React from 'react'

interface ISnackBarMsgProps {
  message?: string
  open?: boolean
  onClose?: any
  onAction?: any
  dismiss?: boolean
  externalDisplay?: boolean
}

const action = onaction => (
  <Button className="color-nav-alert" size="small" onClick={onaction}>
    DISMISS
  </Button>
)
export default function SnackBarMsg(props: ISnackBarMsgProps) {
  const { message, open, onClose, onAction, dismiss, externalDisplay } = props

  return (
    <>
      {dismiss ? (
        <>
          <Snackbar
            className="snackbar"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={open}
            autoHideDuration={1500}
            onClose={onClose}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={
              <span id="message-id" color="secondary">
                {message}
              </span>
            }
          />
        </>
      ) : (
        <Snackbar
          className="snackbar"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={open}
          autoHideDuration={1500}
          onClose={onClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={
            <span id="message-id" color="secondary">
              {message}
            </span>
          }
          action={action(onAction)}
          style={
            externalDisplay
              ? {
                  left: '50%',
                  transform: 'translateX(-50%)',
                }
              : {}
          }
        />
      )}
    </>
  )
}
